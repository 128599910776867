// Height for Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.stuck {
    position: fixed;
    width: 100%;
    left: 0;
    top:0;
    margin: auto;
}

.page-navi {
	z-index: 100;
	background: $dark;
	padding: rem(30px) 0;
	border-top: rem(10px) solid $secondary;


	// Off Canvas Styles
	//////////////////////////////
	
	@include breakpoint(large, max) {
		left: 0;
		padding-top: rem(30px);
		overflow-y: auto;
		position: fixed;
		text-align: center;
		top: rem($menu-bar);
		transform: translateX(-100%);
		transition: .6s;
		width:map-get($breakpoints, tiny) * .8;
		z-index: 895;
	    bottom: 0;
	    max-width: 100%;
	    padding: rem(20px);
	}

	.navi-main {
		@include breakpoint(large) {
			display: flex;
			justify-content: space-between;
		}
		
		li {
			white-space: nowrap;
			font-size: rem(20px);

			@include breakpoint(large) {
				font-size: rem(18px);
			 }
			

			@include breakpoint(giant) {
				font-size: rem(20px);
			 }
			
			
			&.impressum,
			&.datenschutzerklaerung {
				@include breakpoint(large) {
					display: none;
				 }
			}

			a {
				display: block;
				text-decoration: none;
				color: $light;
				font-weight: normal;
				text-align: center;
				padding-bottom: rem(5px);
				line-height: rem(40px);
				border-bottom: 2px solid $dark;

				@include breakpoint(large) {
					line-height: inherit;
				 }
				
			
				&.active {
					color: $secondary;
				}

				&.facebook {
					background:url(/images/icon-fb.png);
					display: block;
					width: 25px;
					height: 24px;
					text-indent: -9999px;
				}
			}

			&:hover,
			&.active {
				a {
					color: $secondary;
					border-bottom: 2px solid $secondary;
				}
			}
		}
	}
}

// Mobile Menu Bar
//////////////////////////////

.mobile-bar {
	align-items: center;
	background: $dark;
	display: flex;
	height: rem($menu-bar);
	justify-content: space-between;
	color: $light;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 894;

	li {
		display: flex;
		flex-grow: 1;
		padding: 0 rem($base-gap);

		&:first-child {

			i {
				transform: rotate(120deg);
			}
		}

		&:last-child {
            justify-content: flex-end;
        }

        label[for="navi-toggled"] {
			flex-direction: row-reverse;
			font-size: rem(18px);

			&:before {
				@extend .fa-solid;
				@extend .fa-bars;
				margin-right: rem(10px);
			}

			&:after {
				background: rgba($dark,0.8);
				bottom: 0;
				content: '';
				left: 0;
				opacity: 0;
				pointer-events: none;
				position: fixed;
				top: rem($menu-bar);
				transition: opacity .6s, visibility .6s;
				visibility: hidden;
				width: 100%;
				z-index: -1;
			}
		}
	}

	@include breakpoint(large) {
		display: none;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			
			&:before {
				@extend .fa-solid;
				@extend .fa-times;
			}

			&:after {
				opacity: 1;
				pointer-events: auto;
				visibility: visible;
			}
		}

		~ * .page-navi, ~ .page-navi {
			transform: translateX(0);
		}
	}
}

.navi-add {
	text-align: center;
	margin-top: rem(40px);
	margin-bottom: rem(30px);

	@include breakpoint(medium) {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;
	}

	a {
		line-height: rem(40px);
		color: $light !important;
		display: block;
		font-size: rem(14px);
		text-decoration: none;

		&:hover, &.active {
			color: $secondary !important;
			text-decoration: none;
		}
	}
}