// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top: rem($menu-bar);
	
	@include breakpoint(large) {
		padding-top: 0;
	}
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	padding: rem(30px) rem($base-gap) rem(10px) rem($base-gap);
	width: 100%;
	font-size: rem(18px);

	.branding {
		display: block;
		float: left;
		border-bottom: 0;
	}

	.address {
		display: none;

		@include breakpoint(medium) {
			display: block;
		 }
		
	}

	i {
		font-size: rem(24px);
		margin-top: rem(-3px);
		margin-right: rem(10px)
	}

	strong {
		font-size: rem(36px);
	}
}

.top {
	background:url(/images/header.jpg) top center no-repeat;
	background-size: cover;
	padding: rem(60px) 0;

	@include breakpoint(large) {
		padding: rem(100px) 0;
	 }
	

	blockquote {
		font-size: rem(25px);
		line-height: rem(30px);
		color: $light;
		text-shadow: 10px 10px 10px rgba($dark, 0.6);
		margin-bottom: rem(40px);

		@include breakpoint(large) {
			font-size: rem(45px);
			line-height: rem(50px);
		 }
		

		strong {
			font-size: rem(35px);
			font-weight: 800;

			@include breakpoint(large) {
				font-size: rem(55px);
			 }
			
		}
	}

	a.btn {
		margin: auto;
	}
}

body.index .top {
	padding: rem(50px) 0;

	@include breakpoint(large) {
		padding: rem(200px) 0;
	 }
	
}

.list {
	background:$dark;
	color: $light;
	padding: rem(30px) 0;

	ul {

		@include breakpoint(large) {
			display: flex;
			justify-content: space-between;
		 }
		
		@extend .styled-list;
		margin-bottom: 0;

		li {
			font-size: rem(23px);
			padding-left: rem(35px);

			&:before {
				color: $secondary;
				top: rem(2px);
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	padding: rem(50px) 0;

	@include breakpoint(large) {
		padding: rem(80px) 0;
	 }
	

	ul, dl {
		@extend .styled-list;
	}

	img.imgbg {
		height: auto;
		

		@include breakpoint(giant) {
			position: absolute;
			left: 51.3%;
			top: rem(100px);
			overflow: hidden;
			margin-bottom: rem(30px);
		 }
		
	}

	img.imgbottom {
		

		@include breakpoint(giant) {
			position: absolute;
			right: 51.3%;
			top: 0;
			bottom: 0;
			overflow: hidden;
		 }
		
	}

	.contentbox {

		padding: rem(58px) rem(0px);
		padding-bottom: rem(20px);

		@include breakpoint(medium) {
			padding: 0 0;
		 }
		

		@include breakpoint(giant) {
			padding: rem(58px) rem(30px);
		 }
		
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}

	.bgImg {
		padding: rem(80px) 0;
	}

	.bg {
		background:$border;
		padding: rem(80px) 0;

		h3 {
			font-size: rem(28px);
			line-height: rem(40px);
			font-weight: 300;
			margin-bottom: rem(40px);

			strong {
				font-weight: 800;
				font-size: rem(53px);
			}
		}
	}
}

body.index main {
	padding-bottom: 0;
	overflow: hidden;
}

.box {
	background:$medium;
	padding: rem(20px);
	text-align: center;
	line-height: rem(32px);
	margin-bottom: rem(35px);

	.tel {
		font-size: rem(27px);
	}
}

.boxList {
	background:#dfdfdf;
	padding: rem(25px);
	margin-bottom: rem(35px);
	
	font-size: rem(18px);

	&:before {
		font-size: rem(18px);
	}

	ul {
		margin-bottom: 0;

		li {
		line-height: rem(31px); }
	}
}

.key {
	margin-top: rem(40px);

	@include breakpoint(giant) {
		margin-top: rem(460px);
	 }

	&:before {
		@extend .fa-solid;
		@extend .fa-key;
		font-size: rem(45px);
		margin-right: rem(20px);
		float: left;

	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	background:$dark;
	color: $light;
	padding: rem(50px) 0;
	text-align: center;

	@include breakpoint(medium) {
		text-align: left;
	 }
	

	a {
		color: $secondary;
	}
}