// Form styles
form.default {
    overflow: hidden;
    width: 100%;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $border;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: $border;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: $border;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $border;
    }

    fieldset {
        border:none;
        margin: 0 0 0 0;
    }

    input, textarea, select, button, [type="submit"] {
        min-height: 2.5rem;
    }

    label {
        color: inherit;
        display: block;
        padding: 0;
        position: relative;
        font-size: rem(14px);
        text-transform: uppercase;

        small {
            color: $alert;
            display: block;
            line-height: .5rem;
            position: absolute;
        }

        &[for="Datenschutz"] {
            @extend .margin-bottom;
            float: left;
            text-transform: none;
            // padding-top: rem(10px);
            width: calc(100% - #{rem(30px)});
        }

        &[for="Nachricht"] {
            display: none;
        }

        &.error {
            -webkit-animation: error 1s;
            animation: error 1s;
            background: $alert;
            display: block;
            text-transform: none;
            color: $light;
            font-size: .9rem;
            max-width: none;
            margin: 0 0 .5rem 0;
            padding-left: rem($base-gap);
            padding-right: rem($base-gap);
            position: relative;
            text-align: center;
            width: 100%;

            @include breakpoint(tiny) {
                font-size: rem(14px);
            }

            &:before {
                border-bottom: rem(10px) solid $alert;
                border-left: rem(7px) solid transparent;
                border-right: rem(7px) solid transparent;
                border-top: 0;
                content: "";
                height: 0;
                position: absolute;
                left: rem(10px);
                top: rem(-10px);
                width: 0;
            }
        }
    }

    [type="text"], [type="tel"], [type="email"], [type="file"], input, textarea, select {
        background: $dark;
        border: none;
        color: $light;
        display: block;
        font-family: $main-font;
        font-size: 1rem;
        line-height: rem($base-line-height);
        margin: 0 0 rem(6px) 0;
        padding: .8rem;
        position: relative;
        resize: none;
        transition: border .3s;
        width: 100%;

        &:focus {
            background: rgba($dark, 0.8);
            outline: none;
            color: $light;
        }
    }

    textarea { /* fake line-height */
        min-height: 7.5rem;
        padding-top: .9rem;

        $inputgroup-count: 3;

        &[name="Nachricht"] {
            min-height: 7.5rem * $inputgroup-count - 4rem;
        }
    }

    [type="checkbox"] {
        display: inline-block;
        float: right;
        margin: 0 0 .5rem rem(15px);
        padding: 0;
        width: rem(15px);
        height: rem(15px);
        background:transparent;

        body.iexplore & {
            border: none;
            
            &:focus {
                background: none;
            }
        }
    }

    [type="radio"] {
        clear: none;
        float: left;
        width: rem(15px);
        margin: .4rem .8rem 0 0;
        min-height: auto;
    }

    button, [type="submit"] {
        background: $secondary;
        border: none;
        font-family: $main-font;
        font-size: 1rem;
        margin: 0 0 1.75rem;
        padding: .8rem $base-gap;
        width: 100%;
        transition: .3s;
        color: $primary;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: rem(2px);
        font-size: rem(17px);

        &:hover, &:focus {
            background: $dark;
            color: $light;
        }
    }

    .errorContainer {
        position: relative;
    }
}

/*=VALIDATION */

@keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.specialfield {
    display: none !important;
    visibility: hidden !important;
}

#newsletter-info {
    background: $alert;
    color: $light;
    font-weight: bold;
    margin-bottom: $base-line-height;
    padding: $base-gap;

    *:last-of-type {
        margin-bottom: 0;
    }
}

img[name=vimg] + input[name=imgverify] {
    float: right;
}